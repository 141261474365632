<!--
 * @Descripttion: 
 * @FilePath: /azg/src/views/daiban/confirm/index.vue
 * @Author: 张兴业
 * @Date: 2021-11-11 16:08:27
 * @LastEditors: zxy
 * @LastEditTime: 2021-12-22 16:59:16
-->
<template>
  <el-form ref="applyAgentForm" label-width="auto" :model="detail">
    <div class="confirm">
      <div>
        <van-cell-group :border="false">
          <van-cell title="绑定手机:" :border="false">
            <template #default>
              <van-field :value="detail.phone" input-align="right" readonly />
            </template>
          </van-cell>

          <van-cell title="推介人：" :border="false">
            <template #default>
              <van-field
                :value="detail.invitationName"
                input-align="right"
                readonly
              />
            </template>
          </van-cell>

          <van-cell title="登记姓名：" :border="false">
            <template #default>
              <van-field
                :value="detail.idcardName"
                input-align="right"
                readonly
              />
            </template>
          </van-cell>

          <van-cell title="登记人身份证：" :border="false">
            <template #default>
              <van-field
                :value="detail.idcardNumber"
                input-align="right"
                readonly
              />
            </template>
          </van-cell>

          <van-cell title="店铺名：" :border="false">
            <template #default>
              <van-field
                v-model="detail.name"
                input-align="right"
                :readonly="detail.agentType !== 1"
              />
            </template>
          </van-cell>

          <van-cell title="营业执照：" :border="false">
            <template #default>
              <van-field
                v-model="detail.businessLicense"
                input-align="right"
                :readonly="detail.agentType !== 1"
              />
            </template>
          </van-cell>
          <!-- ================经营期限================================================================-->

          <template v-if="detail.agentType === 1">
            <van-cell title="经营期限：" :border="false">
              <template #default>
                <div @click="show = true">{{ detail.operatingPeriod }}</div>
              </template>
              <template #right-icon>
                <van-checkbox
                  v-model="detail.isOperatingPeriodLong"
                  shape="square"
                  >长期有效</van-checkbox
                >
              </template>
            </van-cell>
            <Calendar v-model="show" @confirm="onConfirm" @close="onClose" />
          </template>

          <van-cell title="经营期限：" :border="false" v-else>
            <template #default>
              <van-field
                :value="detail.operatingPeriod"
                input-align="right"
                :readonly="detail.agentType !== 1"
              />
            </template>
          </van-cell>
          <!-- ================店铺地址================================================================-->

          <van-cell
            title="店铺地址："
            :value="
              `${detail.provinceName} ${detail.cityName} ${detail.districtName} ${detail.address}`
            "
            :border="false"
            v-if="detail.agentType !== 1"
          />
          <template v-else>
            <van-cell title="店铺地址：" :border="false">
              <template #default>
                <van-field
                  v-model="allAddress"
                  is-link
                  readonly
                  placeholder="请选择所在地区"
                  @click="addreShow = true"
                />
              </template>
            </van-cell>

            <van-popup v-model="addreShow" round position="bottom">
              <van-cascader
                title="请选择所在地区"
                :options="options"
                name=""
                @close="addreShow = false"
                @finish="onFinish"
                :field-names="{
                  text: 'areaName',
                  value: 'areaCode',
                  children: 'childList',
                }"
              />
            </van-popup>
            <van-cell title="店铺详细地址：" :border="false">
              <template #default>
                <van-field v-model="detail.address" placeholder="详细地址" />
              </template>
            </van-cell>
          </template>
          <!-- ================店铺邮箱================================================================-->

          <van-cell title="店铺邮箱：" :border="false">
            <template #default>
              <van-field
                v-model="detail.email"
                input-align="right"
                :readonly="detail.agentType !== 1"
              />
            </template>
          </van-cell>

          <!-- ================店铺证件================================================================-->

          <van-cell title="店铺证件" :border="false" />

          <van-cell :border="false" v-if="detail.agentType === 1">
            <template #default>
              <uplode-input3
                :asyncUpdate="true"
                tip="店铺证件"
                prop="idcardUrl"
                keyName="idcardUrl"
                :value="detail.businessLicenseUrl"
                @uplodeChange="uplodeChange"
              ></uplode-input3>
            </template>
          </van-cell>

          <div class="pics" v-else>
            <div
              class="pic_item"
              @click="preview([detail.businessLicenseUrl], 0)"
            >
              <div class="pic_back">
                <img :src="detail.businessLicenseUrl" alt="" />
              </div>
            </div>
          </div>

          <!-- ================身份证照片================================================================-->

          <van-cell title="身份证照片" :border="false" />
          <div v-if="detail.id" class="pics">
            <div
              class="pic_item"
              @click="preview([detail.idcardFrontUrl, detail.idcardBackUrl], 0)"
            >
              <div class="pic_back">
                <img :src="detail.idcardFrontUrl" alt="" />
              </div>
            </div>
            <div
              class="pic_item"
              @click="preview([detail.idcardFrontUrl, detail.idcardBackUrl], 1)"
            >
              <div class="pic_back">
                <img :src="detail.idcardBackUrl" alt="" />
              </div>
            </div>
          </div>

          <!-- ================门店实景图================================================================-->
          <van-cell title="门店实景图" :border="false" />

          <upload-more
            v-if="detail.agentType === 1"
            v-model="selectShopImgUrls"
            prop="registerCertificationUrl"
          ></upload-more>
          <div class="pics" v-else>
            <div
              v-for="(url, index) in detail.shopImgUrls"
              :key="url"
              class="pic_item"
              @click="preview(detail.shopImgUrls, index)"
            >
              <div class="pic_back">
                <img :src="url" alt="" />
              </div>
            </div>
          </div>
        </van-cell-group>
        <div class="submit" @click="submit">
          提交
        </div>
      </div>
    </div>
  </el-form>
</template>

<script>
import {
  ImagePreview,
  Calendar,
  Checkbox,
  CellGroup,
  Cascader,
  Popup,
} from "vant";
import uplodeInput3 from "@/views/formComponents/uplodeInput3";
import uploadMore from "@/views/formComponents/uploadmore";

export default {
  components: {
    uplodeInput3,
    uploadMore,
    Calendar,
    "van-checkbox": Checkbox,
    "van-popup": Popup,
    "van-cascader": Cascader,
    "van-cell-group": CellGroup,

    [ImagePreview.Component.name]: ImagePreview.Component,
  },
  data() {
    return {
      loading: false,
      show: false,
      addreShow: false,
      detail: {},
      options: [],
      selectShopImgUrls: [],
    };
  },
  computed: {
    allAddress() {
      return `${this.detail.provinceName}${this.detail.cityName}${this.detail.districtName}`;
    },
  },
  mounted() {
    this.getAddressData();
    this.getInitDetail();
  },
  methods: {
    onFinish({ selectedOptions }) {
      this.addreShow = false;

      if (selectedOptions.length == 3) {
        this.detail.provinceName = selectedOptions[0].areaName;
        this.detail.cityName = selectedOptions[1].areaName;
        this.detail.districtName = selectedOptions[2].areaName;

        this.detail.provinceId = selectedOptions[0].areaCode;
        this.detail.cityId = selectedOptions[1].areaCode;
        this.detail.districtId = selectedOptions[2].areaCode;
      }
    },
    getAddressData() {
      // 获取地址数据
      const url = "/api/front/order/address/getAllAddress";
      this.$request(url)
        .then((res) => {
          if (res.code === 1) {
            this.options = res.data;
          }
        })
        .catch(() => {});
    },
    formatDate(date) {
      var mmonth = `${date.getMonth() + 1}`;
      if (`${date.getMonth() + 1}`.length == 1) {
        mmonth = `0${date.getMonth() + 1}`;
      }

      var mdate = `${date.getDate()}`;
      if (`${date.getDate()}`.length == 1) {
        mdate = `0${date.getDate()}`;
      }
      return `${date.getFullYear()}-${mmonth}-${mdate}`;
    },
    getInitDetail() {
      console.log("getInitDetail=======");
      let objStr = localStorage.getItem("RegisterInfo");
      var obj = JSON.parse(objStr);

      if (obj.operatingPeriod === "长期有效") {
        obj.isOperatingPeriodLong = true;
      } else {
        obj.isOperatingPeriodLong = false;
      }

      this.detail = obj;
      this.selectShopImgUrls = obj.shopImgUrls.map(function(item) {
        return { url: item };
      });
    },

    onClose() {
      this.show = false;
    },
    onConfirm(date) {
      this.show = false;
      this.detail.isOperatingPeriodLong = false;
      this.detail.operatingPeriod = this.formatDate(date);
    },
    uplodeChange(value) {
      this.detail.businessLicenseUrl = value.idcardUrl ?? "";
    },
    preview(pics, index) {
      ImagePreview({
        images: pics,
        startPosition: index,
      });
    },
    submit() {
      if (this.loading) {
        return;
      }
      let { inviteCode: invitationCode, id: storeDsId } = this.detail;

      var params = { invitationCode, storeDsId };
      // 如果是主代理
      if (this.detail.agentType === 1) {
        if (this.detail.isOperatingPeriodLong) {
          this.detail.operatingPeriod = "长期有效";
        }
        this.detail.data = {
          shopImgUrls: this.selectShopImgUrls.map((item) => item.url),
        };
        params.storeDs = this.detail;
      }

      let token = localStorage.getItem("RegisterToken");
      this.$request("/api/front/distributorDs/storeDsSecondCertification", {
        method: "POST",
        data: params,
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          this.loading = false;
          if (res.code === 1) {
            localStorage.removeItem("RegisterInfo");
            localStorage.removeItem("RegisterToken");
            this.$router.push("/download/app/distributor");
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.confirm {
  background: white;

  ::v-deep .van-nav-bar {
    background: rgba(253, 40, 36, 0.05);
    font-size: 16px;
    font-weight: 500;
  }

  ::v-deep .van-cell__title {
    text-align: left;
  }

  .pics {
    display: flex;
    flex-wrap: wrap;
    padding: 0 12px;

    .pic_item {
      width: calc(50vw - 12px);

      .pic_back {
        text-align: center;
        position: relative;
        width: 100%;
        height: 0;
        padding-top: 60%;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 90%;
        height: 90%;
        max-width: 90%;
        max-height: 90%;
        margin-top: 5%;
        margin-left: 5%;
      }
    }
  }

  .agree {
    padding: 32px;
  }

  .hint {
    font-size: 12px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    padding: 0 36px;
    text-align: left;
  }

  .submit {
    margin: 40px 12px 24px 12px;
    background: linear-gradient(90deg, #fd2824, #fe3d6c 100%);
    font-weight: 500;
    font-size: 14px;
    color: white;
    cursor: pointer;
    height: 36px;
    line-height: 36px;
    border-radius: 18px;
  }
}
</style>
